<template>
    <div>
        <template>
            <div id="breadcrumb-alignment" class="mb-1">
                <div class="d-flex justify-content-start breadcrumb-wrapper">
                    <b-breadcrumb :items="breadCrumb()" />
                </div>
            </div>
        </template>

        <b-card>
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{ error_message }}
                </div>
            </b-alert>

            <b-row>

              <b-col md="12">              
                  <h4 class="card-title float-left">Add Order</h4>            
              </b-col>
          
            </b-row>

            <b-row>
                <b-col md="3">
                  <b-form-group label="Project Site" class="required">
                      <b-form-select v-model="site" @change="siteChange();" :disabled="disabledOptions">
                        <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                        <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Order Type" class="required">
                        <b-form-select v-model="order_type" @change="changetype" :disabled="disabledOptions">
                        <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                        <b-form-select-option value="monthly" :disabled="!checkOrderCount">Monthly</b-form-select-option>
                        <b-form-select-option value="adhoc" v-if="$store.getters.currentUser.role != 'supervisor'">Adhoc</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col md="3" >
                    <b-form-group label="Date" class="required"> 
                    <b-form-datepicker
                        v-model="date"
                        :date-format-options="{ day: 'numeric', month: 'short', year: 'numeric' }"
                        locale="en"
                        :disabled="$store.getters.currentUser.role != 'admin'"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="3" >
                    <b-form-group label="Order By" class="required">
                    <b-form-input placeholder="3 to 50 characters" v-model="order_by" autocomplete="off" :disabled="disabledOptions"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>

                <b-col md="3">
                     
                    <h5 class="mt-1">Order for a Month : {{ month }}</h5>                               
                </b-col>

                
            </b-row>

        </b-card>
        <b-form @submit="formSubmit">
            <b-card no-body class="mb-1" v-for="(list, i) in orderData" :key="i">
                <div v-if="site != ''">

                    <div class="table-responsive" style="margin-bottom: 0px !important;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col" colspan="7" style="padding-top: 22px; width: 100%;">
                                        <b-row>
                                            <b-col md="8">
                                                <b-row>
                                                    <b-col md="3">
                                                        <!-- <b-form-select v-model="list.supplier" @change="updateSupplier(i); flag_data = false" :disabled="disabledOptions"> removed from Issac Feedback-->
                                                        <b-form-select v-model="list.supplier" @change="updateSupplier(i); flag_data = false">
                                                            <b-form-select-option value="" disabled>Select Supplier</b-form-select-option>
                                                            <b-form-select-option :value="supplier._id" :disabled="supplierDisable(supplier._id)" v-for="supplier in suppliers" :key="supplier._id + '' + i">{{ supplier.name | capitalize }}</b-form-select-option>
                                                        </b-form-select>
                                                    </b-col>

                                                    <b-col md="3">
                                                        <b-form-select v-model="list.category" @change="updateCategory(i)">
                                                            <b-form-select-option value="" disabled>Select Category</b-form-select-option>
                                                            <b-form-select-option :value="category._id" v-for="category in list.categories" :key="category._id + '' + i">{{ category.name | capitalize }}</b-form-select-option>
                                                        </b-form-select>
                                                    </b-col>

                                                    <b-col md="3">
                                                        <b-form-select v-model="list.sub_category">
                                                            <b-form-select-option value="" disabled>Select Sub Category</b-form-select-option>
                                                            <b-form-select-option :value="sub_category._id" v-for="sub_category in list.sub_categories" :key="sub_category._id + '' + i">{{ sub_category.name | capitalize }}</b-form-select-option>
                                                        </b-form-select>
                                                    </b-col>

                                                    <b-col md="3">
                                                        <b-form-input placeholder="Search Items" v-model="list.filter_items" @input="matchItem(i);" autocomplete="off"/>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col md="4">
                                                <b-row>
                                                    <b-col md="4">
                                                        <b-button
                                                            
                                                            variant="success"
                                                            class="mr-1 float-left"
                                                            @click="resetFilter(i); list.filter_items='';"                                            
                                                            >
                                                            Reset
                                                        </b-button>
                                                    </b-col>
                                                    <b-col md="4">
                                                        <b-button
                                                            variant="warning"
                                                            class="mr-1 float-right"
                                                            @click="updateSupplierData(i, orderData[i]); flag_data = false;"
                                                            v-if="orderData.length > 0 && orderData[0].data.length > 0"
                                                            >
                                                            Refresh
                                                        </b-button>
                                                    </b-col>
                                                    <b-col md="4">
                                                        <!-- :disabled="disabledOptions" removed from Issac Feedback -->
                                                        <b-button
                                                            
                                                            variant="danger"
                                                            class="mr-1 float-right"
                                                            @click="removeData(i); flag_data = true;"
                                                            v-if="orderData.length > 0 && orderData[0].data.length > 0 && orderData[i].db_order == false"
                                                            >
                                                            Remove
                                                        </b-button>
                                                        <!-- :disabled="disabledOptions" removed from Issac Feedback -->
                                                        <b-button
                                                            
                                                            variant="danger"
                                                            class="mr-1 float-right"
                                                            @click="removeDBData(i, orderData[i]); flag_data = true;"
                                                            v-if="orderData.length > 0 && orderData[0].data.length > 0 && orderData[i].db_order == true"
                                                            >
                                                            Remove
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            
                                            
                                        </b-row>

                                    </th>
                                    
                                </tr>
                                <tr>
                                    <th scope="col" style="width: 5%;">
                                       {{ '#' }}
                                    </th>
                                    <th scope="col" style="width: 25% !important">
                                        {{ 'Item Name' }}  
                                    </th>
                                    <th scope="col" style="width:14%">
                                        {{ 'Packing' }}
                                    </th>
                                    <th scope="col" style="width:14%">
                                        {{ 'Rate' }}
                                    </th>
                                    <th scope="col" style="width:14%">
                                        {{ 'Qty' }}
                                    </th>
                                    <th scope="col" style="width:14%">
                                        {{ 'Amt' }}
                                    </th>
                                    <th scope="col" style="width:14%">
                                        {{ 'Bal' }}
                                    </th>
                                    

                                </tr>
                            </thead>
                            <tbody v-show="show_all_items">
                                
                                <tr style="height:70px;" v-for="(l, index) in list.data" v-if="(list.data.length > 0) && l.matched == true &&
                                ( (list.category == '' && list.sub_category == '') || 
                                (list.category != '' && list.sub_category == '' && list.category == l.category) || 
                                (list.category != '' && list.sub_category != '' && list.sub_category != 'general'  && list.sub_category == l.sub_category) || 
                                (list.category != '' && list.sub_category != '' && list.sub_category == 'general' && list.category == l.category  && l.sub_category == null))">
                                    <td>

                                        <b-form-checkbox
                                        v-model="l.enabled"
                                        value="yes"
                                        unchecked-value="no"
                                        class="mb-0 mt-0"
                                        @change="updateItemValue(i,index)"
                                        >
                                    
                                        </b-form-checkbox>
                                    </td>

                                    <td>
                                        
                                        <span class="h6 text-light">

                                            <span class="wordBreak">{{ l.name }}</span>
                                            <b-badge pill variant="warning" class="text-capitalize" v-if="l.most_order == 'yes'">Popular Item</b-badge>
                                            
                                        </span>
                                    </td>

                                    <td>
                                        <span>{{ l.packing }}</span>
                                    </td>
                                    <td>
                                        <span>{{ l.unit_price.toFixed(2) | showReadNum }}</span>  
                                    </td>

                                    <td>
                                        <b-form-input
                                            placeholder=""
                                            v-model="l.qty"
                                            type="text"
                                            autocomplete="off"
                                            @change="calAmount(i, index)"
                                            @keypress="isNumber($event)"
                                             v-if="l.enabled == 'yes'"
                                        />   
                                        <span v-if="l.enabled == 'no'"> {{ '0' }}</span>  
                                    </td>

                                    <td>
                                        <span v-if="l.amount != ''">{{l.amount | showReadNum }}</span> 
                                        <span v-else>{{ '$0.00' }}</span>                
                                    </td>
                                    <td>
                                        <b-form-input
                                            placeholder=""
                                            v-model="l.bal"
                                            type="text"
                                            autocomplete="off"
                                            @keypress="isNumber($event)"
                                            v-if="l.enabled == 'yes'"
                                        />
                                        <span v-if="l.enabled == 'no'"> {{ l.bal }}</span>    
                                    </td>
                                    

                                      
                                </tr>
                                <tr v-if="list.data.length == 0">
                                    <td colspan="7" class="text-center">
                                        <span >{{ 'No records found' }}</span>
                                            
                                    </td>
                                </tr>
                            </tbody>

                            
                            <tbody v-show="!show_all_items">
                                
                                <tr style="height:70px;" v-for="(l, index) in list.data" v-if="(list.data.length > 0) && l.matched == true &&
                                ( (list.category == '' && list.sub_category == '') || 
                                (list.category != '' && list.sub_category == '' && list.category == l.category) || 
                                (list.category != '' && list.sub_category != '' && list.sub_category != 'general'  && list.sub_category == l.sub_category) || 
                                (list.category != '' && list.sub_category != '' && list.sub_category == 'general' && list.category == l.category  && l.sub_category == null)) && (l.qty > 0)">
                                    <td>

                                        <b-form-checkbox
                                        v-model="l.enabled"
                                        value="yes"
                                        unchecked-value="no"
                                        class="mb-0 mt-0"
                                        @change="updateItemValue(i,index)"
                                        >
                                    
                                        </b-form-checkbox>
                                    </td>

                                    <td>
                                        
                                        <span class="h6 text-light">

                                            <span class="wordBreak">{{ l.name }}</span>
                                            <b-badge pill variant="warning" class="text-capitalize" v-if="l.most_order == 'yes'">Popular Item</b-badge>
                                            
                                        </span>
                                    </td>

                                    <td>
                                        <span>{{ l.packing }}</span>
                                    </td>
                                    <td>
                                        <span>{{ l.unit_price.toFixed(2) | showReadNum }}</span>  
                                    </td>

                                    <td>
                                        <b-form-input
                                            placeholder=""
                                            v-model="l.qty"
                                            type="text"
                                            autocomplete="off"
                                            @change="calAmount(i, index)"
                                            @keypress="isNumber($event)"
                                             v-if="l.enabled == 'yes'"
                                        />   
                                        <span v-if="l.enabled == 'no'"> {{ '0' }}</span>  
                                    </td>

                                    <td>
                                        <span v-if="l.amount != ''">{{l.amount | showReadNum }}</span> 
                                        <span v-else>{{ '$0.00' }}</span>                
                                    </td>
                                    <td>
                                        <b-form-input
                                            placeholder=""
                                            v-model="l.bal"
                                            type="text"
                                            autocomplete="off"
                                            @keypress="isNumber($event)"
                                            v-if="l.enabled == 'yes'"
                                        />
                                        <span v-if="l.enabled == 'no'"> {{ l.bal }}</span>    
                                    </td>
                                    

                                      
                                </tr>
                                <tr v-if="list.data.length == 0">
                                    <td colspan="7" class="text-center">
                                        <span >{{ 'No records found' }}</span>
                                            
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div>
                        <hr />
                        <b-row>
                            <b-col md="6">
                                
                                <h5 class="float-left mt-1 mb-0 ml-2" v-if="orderData.length > 0">Sub Total Amount : {{ updateSubPrice(i) | showReadNum }}</h5>
                                
                            </b-col>
                            <b-col md="6">
                            
                        
                            </b-col>  
                        </b-row>
                        <hr>   

                        <b-row>
                            <b-col md="6">
                                
                                <h6 class="float-left mt-1 mb-2 ml-2" v-if="orderData.length > 0">
                                    <span v-if="list.fee_weiver == 'yes' && (Number(updateSubPrice(i)) >= Number(list.moq))">Delivery Fee : <s>{{ list.delivery_fee | showReadNum }}</s></span>
                                    <span v-else>Delivery Fee : {{ list.delivery_fee | showReadNum }}</span>
                                </h6>
                            
                            </b-col>
                            <b-col md="6">
                                
                                <h6 class="float-left mt-1 mb-2 ml-2" v-if="orderData.length > 0">Minimum Order Amount : {{ list.moq  | showReadNum }}</h6>
                    
                            </b-col>     
                        </b-row>

                        

                    </div>
                    
                </div>
            </b-card>
            
            <b-card class="mt-1" v-if="orderData.length > 0 && orderData[0].data.length > 0">
                <b-row class="mb-2">
                    <b-col md="6">
                        <!-- <h5>Total Amount - $ {{ updatePrice() | showReadNum }}</h5> -->
                    </b-col>
                    <b-col md="6">
                        
                        <!-- :disabled="disabledOptions" removed from Issac Feedback -->
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="mr-1 float-right"
                            @click="addMore(); flag_data = false;"
                            v-if="site != ''"
                            >
                            Add More
                        </b-button>
                        
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">

                        <b-form-group
                        label="Special Requisition (Please Indicate)"
                        class=""
                        >
                        <b-form-textarea
                            placeholder="upto 500 characters"
                            rows="3"
                            v-model="special_requisition"
                        />
                        </b-form-group>


                    </b-col>
                    
                </b-row>
            </b-card>

            <b-card class="mt-1" v-if="(orderData.length > 0 && orderData[0].data.length > 0) && (!['supervisor', 'crew'].includes($store.getters.currentUser.role))">    
                <h3 class="text-center mb-1"> Delivery Information </h3>

                <!-- <b-row v-if="multiple_delivery_option == 'yes'"> -->
                <b-row>
                    <b-col cols="12" class="">
                        <!-- <b-form-checkbox v-model="enable_multiple_supplier_delivery_site" value="yes" unchecked-value="no" class="mb-0 mt-0" :disabled="disabledOptions" @input="updateSite();">
                            Deliver to Other Project Site
                        </b-form-checkbox> -->
                        <!-- <b-form-checkbox v-model="enable_multiple_supplier_delivery_site" value="yes" unchecked-value="no" class="mb-0 mt-0" @input="updateSite();">
                            Deliver to Other Project Site
                        </b-form-checkbox> -->
                        <b-form-radio v-model="enable_multiple_supplier_delivery_site" value="yes" class="mb-0 mt-0" @change="updateSite()" style="display: inline-block; margin-right: 15px;">
                            Deliver to Other Project Site
                        </b-form-radio>
                        <b-form-radio v-model="enable_multiple_supplier_delivery_site" value="no" class="mb-0 mt-0" @change="updateSite()" style="display: inline-block;">
                            Self Collect
                        </b-form-radio>
                    </b-col>

                    <b-col md="4" sm="6" v-for="(el, ind) in orderData" :key="ind" class="" v-if="enable_multiple_supplier_delivery_site == 'no' && orderData.length > 0 && orderData[0].supplier.length > 0" class="mt-2" style="">
                        
                        <b-card v-if="el.supplier && el.supplier.length > 0" no-body class="card-developer-meetup h-100 p-1 mb-0" style="border: 1px solid #f4f4f4;">
                            <div v-for="item in suppliers" :key="item._id">

                                <div v-if="item._id == el.supplier">

                                    <div class="">
                                        <div class="mb-1">
                                            <b-row>
                                                <b-col cols="12" class="pb-1">
                                                    Collect at - {{item.name}}
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>

                                    <b-media no-body>
                                        <b-media-aside class="mr-1">
                                            <b-avatar rounded variant="light-danger" size="20">
                                                <feather-icon icon="MapPinIcon" size="15"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body>
                                            <h6 class="mb-0 wordBreak">{{ item.address | capitalize }}</h6>
                                        </b-media-body>
                                    </b-media>

                                    <b-media no-body>
                                        <b-media-aside class="mr-1">
                                            <b-avatar rounded variant="light-warning" size="20">
                                                <feather-icon icon="UserIcon" size="15"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body>
                                            <h6 class="mb-0" v-if="item.attn.length > 0"> {{item.attn | capitalize}}</h6>
                                            <h6 class="mb-0" v-else> N/A </h6>
                                        </b-media-body>
                                    </b-media>

                                    <b-media no-body>
                                        <b-media-aside class="mr-1">
                                            <b-avatar rounded variant="light-success" size="20">
                                                <feather-icon icon="PhoneIcon" size="15"/>
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body>
                                            <h6 class="mb-0" v-if="item.phone_number"> {{ showNumberFormat(item.phone_number, item.country) }} </h6>
                                            <h6 class="mb-0" v-else> N/A </h6>
                                        </b-media-body>
                                    </b-media>

                                </div>
                                
                            </div>
                            
                        </b-card>
                        <b-card v-else no-body class="card-developer-meetup h-100 p-1 mb-0 text-center" style="border: 1px solid #f4f4f4;">
                            <h5 class="text-secondary">Please Select Supplier</h5>
                        </b-card>
                    </b-col>

                    <!-- <b-col md="4" sm="6" v-for="(item, ind) in deliveryData" :key="ind" class="" v-if="enable_multiple_supplier_delivery_site == 'no'" class="mt-2" style="opacity: 0.5; pointer-events: none;">
                      <b-card no-body class="card-developer-meetup h-100 p-1 mb-0" style="border: 1px solid #f4f4f4;">


                              <div class="">

                                  <div class="mb-1">
                                      <b-row>
                                          <b-col cols="12" class="pb-1">
                                              Deliver to -
                                          </b-col>
                                      </b-row>
                                      <b-row class="px-0">
                                          <b-col cols="9" class="">                                                

                                              <b-form-select v-model="item.site" @change="dlSiteChange($event, ind)">
                                                  <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                                                  <b-form-select-option :disabled="siteDisable(site._id)" :value="site._id" v-for="site in sites" :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option>
                                              </b-form-select>
                                          </b-col>
                                          <b-col cols="3" class="pl-0 ml-0">

                                                  <div @click="item._showDetails = item._showDetails ? false : true;" class="float-left" style="padding-top: 7px;">
                                                      <feather-icon icon="EyeOffIcon" class="text-warning" v-if="item._showDetails"/>
                                                      <feather-icon icon="EyeIcon" class="text-warning" v-else/>
                                                  </div>

                                              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="float-right" @click="removeDeliveryItem(ind)" v-if="multiple_delivery_option == 'yes'" :disabled="disabledOptions && item._from_db">
                                                  <feather-icon icon="XIcon" class="" />
                                              </b-button>
                                          </b-col>
                                      </b-row>
                                  </div>
                              </div>
                              
                              <b-media no-body v-if="!item._showDetails">
                                  <b-media-aside class="mr-1">
                                      <b-avatar rounded variant="light-danger" size="20">
                                          <feather-icon icon="MapPinIcon" size="15"/>
                                      </b-avatar>
                                  </b-media-aside>
                                  <b-media-body>
                                      <h6 class="mb-0">{{ item.address | capitalize }}</h6>
                                  </b-media-body>
                              </b-media>
                              
                              <b-media no-body v-if="!item._showDetails">
                                  <b-media-aside class="mr-1">
                                      <b-avatar rounded variant="light-warning" size="20">
                                          <feather-icon icon="UserIcon" size="15"/>
                                      </b-avatar>
                                  </b-media-aside>
                                  <b-media-body>
                                      <h6 class="mb-0" v-if="item.user"> {{item.user.full_name | capitalize}} ({{item.user.role | removeUnderCapitalize}}) </h6>
                                      <h6 class="mb-0" v-else> N/A </h6>
                                  </b-media-body>
                              </b-media>
                              
                              <b-media no-body v-if="!item._showDetails">
                                  <b-media-aside class="mr-1">
                                      <b-avatar rounded variant="light-success" size="20">
                                          <feather-icon icon="PhoneIcon" size="15"/>
                                      </b-avatar>
                                  </b-media-aside>
                                  <b-media-body>
                                      <h6 class="mb-0" v-if="item.user"> {{ showNumberFormat(item.user.phone_number, item.country) }} </h6>
                                      <h6 class="mb-0" v-else> N/A </h6>
                                  </b-media-body>
                              </b-media>


                      </b-card>
                  </b-col> -->
                  <b-col md="4" sm="6" v-for="(item, ind) in multiDeliveryData" :key="ind" v-if="enable_multiple_supplier_delivery_site == 'yes'" class="mt-2">
                      <b-card no-body class="card-developer-meetup h-100 p-1 mb-0" style="border: 1px solid #f4f4f4;">
                          <!-- <b-card-body> -->
                              <!-- {{ item }} -->
                              <div class="mb-1">
                                  <!-- <div v-if="multiple_delivery_option == 'yes'" class="mb-1"> -->
                                      <b-row>
                                          <b-col cols="12" class="pb-1">
                                              Supplier Name : {{ item.supplier_name }}
                                          </b-col>
                                      </b-row>
                                      <b-row class="px-0">
                                          <b-col cols="11" class="">
                                            <b-form-select v-model="item.site" @change="msdSiteChange(ind)" :disabled="disabledOptions && item._from_db">
                                                  <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                                                  <!-- <b-form-select-option :disabled="siteDisableMSD(site._id)" :value="site._id" v-for="site in sites" :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option> -->
                                                  <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option>
                                              </b-form-select>
                                          </b-col>
                                          <b-col cols="1" class="pl-0 ml-0">
                                              <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" > -->
                                                  <div @click="item._showDetails = item._showDetails ? false : true;" class="float-left" style="padding-top: 7px;">
                                                      <feather-icon icon="EyeOffIcon" class="text-warning" v-if="item._showDetails"/>
                                                      <feather-icon icon="EyeIcon" class="text-warning" v-else/>
                                                  </div>
                                              <!-- </b-button> -->
                                              <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="float-right" @click="removeDeliveryItem(ind)" v-if="multiple_delivery_option == 'yes'" :disabled="disabledOptions && item._from_db">
                                                  <feather-icon icon="XIcon" class="" />
                                              </b-button> -->
                                          </b-col>
                                      </b-row>
                                  <!-- </div> -->
                              </div>
                              
                              <b-media no-body v-if="!item._showDetails">
                                  <b-media-aside class="mr-1">
                                      <b-avatar rounded variant="light-danger" size="20">
                                          <feather-icon icon="MapPinIcon" size="15"/>
                                      </b-avatar>
                                  </b-media-aside>
                                  <b-media-body>
                                      <h6 class="mb-0">{{ item.address | capitalize }}</h6>
                                  </b-media-body>
                              </b-media>

                              <b-media no-body v-if="!item._showDetails">
                                  <b-media-aside class="mr-1">
                                      <b-avatar rounded variant="light-warning" size="20">
                                          <feather-icon icon="UserIcon" size="15"/>
                                      </b-avatar>
                                  </b-media-aside>
                                  <b-media-body>
                                      <h6 class="mb-0" v-if="item.user"> {{item.user.full_name | capitalize}} ({{item.user.role | removeUnderCapitalize}}) </h6>
                                      <h6 class="mb-0" v-else> N/A </h6>
                                  </b-media-body>
                              </b-media>
                              
                              <b-media no-body v-if="!item._showDetails">
                                  <b-media-aside class="mr-1">
                                      <b-avatar rounded variant="light-success" size="20">
                                          <feather-icon icon="PhoneIcon" size="15"/>
                                      </b-avatar>
                                  </b-media-aside>
                                  <b-media-body>
                                      <h6 class="mb-0" v-if="item.user"> {{ showNumberFormat(item.user.phone_number, item.country) }} </h6>
                                      <h6 class="mb-0" v-else> N/A </h6>
                                  </b-media-body>
                              </b-media>

                          <!-- </b-card-body> -->
                      </b-card>
                    </b-col>
                </b-row>

                <!-- <b-row v-else>
                    <b-col cols="4" v-for="(item, ind) in deliveryData" :key="ind" class="mb-2">
                        <b-card no-body class="card-developer-meetup h-100 p-1 mb-0" style="border: 1px solid #f4f4f4;">


                                <div class="mb-1">                                          
                                    
                                    <b-row class="px-0">
                                        <b-col cols="11" class="">                                                
                                            <b-form-select v-model="item.site" @change="dlSiteChange($event, ind)" :disabled="disabledOptions || item._from_db">
                                                <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                                                <b-form-select-option :disabled="siteDisable(site._id)" :value="site._id" v-for="site in sites" :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col cols="1" class="pl-0 ml-0">
                                                <div @click="item._showDetails = item._showDetails ? false : true;" class="float-right" style="padding-top: 7px;">
                                                    <feather-icon icon="EyeOffIcon" class="text-warning" v-if="item._showDetails"/>
                                                    <feather-icon icon="EyeIcon" class="text-warning" v-else/>
                                                </div>
                                        </b-col>
                                    </b-row>

                                </div>
                                
                                <b-media no-body v-if="!item._showDetails">
                                    <b-media-aside class="mr-1">
                                        <b-avatar rounded variant="light-danger" size="20">
                                            <feather-icon icon="MapPinIcon" size="15"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body>
                                        <h6 class="mb-0">{{ item.address | capitalize }}</h6>
                                    </b-media-body>
                                </b-media>
                                
                                <b-media no-body v-if="!item._showDetails">
                                    <b-media-aside class="mr-1">
                                        <b-avatar rounded variant="light-warning" size="20">
                                            <feather-icon icon="UserIcon" size="15"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body>
                                        <h6 class="mb-0" v-if="item.user"> {{item.user.full_name | capitalize}} ({{item.user.role | removeUnderCapitalize}}) </h6>
                                        <h6 class="mb-0" v-else> N/A </h6>
                                    </b-media-body>
                                </b-media>
                                
                                <b-media no-body v-if="!item._showDetails">
                                    <b-media-aside class="mr-1">
                                        <b-avatar rounded variant="light-success" size="20">
                                            <feather-icon icon="PhoneIcon" size="15"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body>
                                        <h6 class="mb-0" v-if="item.user"> {{ showNumberFormat(item.user.phone_number, item.country) }} </h6>
                                        <h6 class="mb-0" v-else> N/A </h6>
                                    </b-media-body>
                                </b-media>


                        </b-card>
                    </b-col>
                </b-row> -->
                
                <!-- <b-row v-if="multiple_delivery_option == 'yes'">
                    
                    <b-table
                        responsive
                        show-empty
                        ref = "refdeliveryListTable"
                        class = "position-relative reverse-padding"
                        primary-key = "id"
                        empty-text = "No records found"
                        :fields = "tableColumns2"
                        :items = "deliveryData"
                        v-if="site != ''"
                    >

                        <template #cell(s_no)="items">
                            
                            <p>{{ items.index + 1 }}</p>         
                        </template>

                        <template #cell(site)="items">
                            <div v-if="multiple_delivery_option == 'yes'">

                                <b-form-select v-model="items.item.site" @change="dlSiteChange($event,items.index)" :disabled="disabledOptions">
                                    <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option>
                                </b-form-select>

                            </div>
                            <div v-else>
                                {{ items.item.site_name }}
                            </div>
                            
                        </template>

                        <template #cell(address)="items">
                            
                            <span>{{ items.item.address | capitalize }}</span>
                            
                        </template>

                        <template #cell(action)="items">
                                
                            <b-button
                        
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="mr-1 mb-1 mt-1 float-right"
                                @click="removeDeliveryItem(items.index)"
                                v-if="multiple_delivery_option == 'yes'"
                                :disabled="disabledOptions"
                                >
                                X
                            </b-button>

                        </template>

                        <template #row-details="items">
                                <div class="p-1 px-2 mb-1">
                                    <b-row>
                                    
                                        <b-col cols="12" xl="12"  v-if="items.item.user != null">
                                            <table class="mt-2 mt-xl-0 w-100">
                                                <tr class="mb-1">
                                                    <th class="pb-50" style="width: 25%">
                                                        <div class="d-flex align-items-center">
                            
                                                        <div class="ml-1">
                                                            <h6 class="mb-0 mediumFonts">
                                                            {{ items.item.user.role | removeUnderCapitalize }} Name 
                                                            </h6>
                                
                                                        </div>
                                                        </div>
                                                    </th>
                                                    <td class="pb-50 mediumFonts" style="width: 25%;padding-top: 8px;">
                                                        {{ items.item.user.full_name | capitalize }}
                                                    </td>

                                                    <th class="pb-50" style="width: 25%">
                                                            <div class="d-flex align-items-center">
                            
                                                            <div class="ml-1">
                                                                <h6 class="mb-0 mediumFonts">
                                                                Contact Number 
                                                                </h6>
                                
                                                            </div>
                                                            </div>
                                                        </th>
                                                        <td class="pb-50 mediumFonts" style="width: 25%;padding-top: 8px;">
                                                            {{ showNumberFormat(items.item.user.phone_number, items.item.country) }}
                                                        </td>
                                            
                                            
                                                </tr>


                                            </table>
                                        </b-col>

                                        <b-col cols="12" xl="12" v-if="items.item.user == null">
                                            <table class="mt-2 mt-xl-0 w-100">
                                                    <tr class="mb-1">
                                                        <th class="pb-50" style="width: 100%">
                                                            <div class="text-center align-items-center">
                            
                                                                <div class="ml-1">
                                                                    <h6 class="mb-0">
                                                                    Contact person details not available
                                                                    </h6>
                                    
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>


                                                </table>
                                        </b-col>

                                    </b-row>
                                </div>
                            </template>
                            

                    </b-table>
                </b-row>
                <b-row v-else>
                    <b-table
                        responsive
                        show-empty
                        ref = "refdeliveryListTable"
                        class = "position-relative reverse-padding"
                        primary-key = "id"
                        empty-text = "No records found"
                        :fields = "tableColumns3"
                        :items = "deliveryData"
                        v-if="site != ''"
                    >
                        <template #cell(site)="items">
                            <b-form-select v-model="items.item.site" @change="dlSiteChange($event, items.index)" :disabled="disabledOptions">
                                <b-form-select-option value="" disabled>Select</b-form-select-option>                    
                                <b-form-select-option :value="site._id" v-for="site in sites"  :key="site._id">{{ site.site_name | capitalize }}</b-form-select-option>
                            </b-form-select>
                        
                        </template>

                        <template #cell(address)="items">
                        
                            <span>{{ items.item.address | capitalize }}</span>
                        
                        </template>

                        <template #row-details="items">
                            <div class="p-1 px-2 mb-1">
                                <b-row>
                                    

                                    <b-col cols="12" xl="12"  v-if="items.item.user != null">
                                        <table class="mt-2 mt-xl-0 w-100">
                                            <tr class="mb-1">
                                                <th class="pb-50" style="width: 25%">
                                                    <div class="d-flex align-items-center">
                            
                                                    <div class="ml-1">
                                                        <h6 class="mb-0 mediumFonts">
                                                        {{ items.item.user.role | removeUnderCapitalize }} Name 
                                                        </h6>
                                
                                                    </div>
                                                    </div>
                                                </th>
                                                <td class="pb-50 mediumFonts" style="width: 25%;padding-top: 8px;">
                                                    {{ items.item.user.full_name | capitalize }}
                                                </td>

                                                <th class="pb-50" style="width: 25%">
                                                        <div class="d-flex align-items-center">
                            
                                                        <div class="ml-1">
                                                            <h6 class="mb-0 mediumFonts">
                                                            Contact Number 
                                                            </h6>
                                
                                                        </div>
                                                        </div>
                                                    </th>
                                                    <td class="pb-50 mediumFonts" style="width: 25%;padding-top: 8px;">
                                                        {{ showNumberFormat(items.item.user.phone_number, items.item.country) }}
                                                    </td>
                                            
                                            
                                            </tr>


                                        </table>
                                    </b-col>

                                    <b-col cols="12" xl="12" v-if="items.item.user == null">
                                        <table class="mt-2 mt-xl-0 w-100">
                                                <tr class="mb-1">
                                                    <th class="pb-50" style="width: 100%">
                                                        <div class="text-center align-items-center">
                            
                                                            <div class="ml-1">
                                                                <h6 class="mb-0">
                                                                Contact person details not available 
                                                                </h6>
                                    
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>


                                            </table>
                                    </b-col>

                                    </b-row>
                            </div>
                        </template>

                    
                    </b-table>
                    
                </b-row> -->
                
                <!-- <b-row v-if="multiple_delivery_option == 'yes'">
                    
                    <b-col md="12">
                    
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="mr-1 float-right"
                            @click="addDLMore"
                            :disabled="sites.length == deliveryData.length"
                        >

                            Add More
                        </b-button>

                    </b-col>
                </b-row> -->

            </b-card>   

            <b-card class="mt-1" v-if="(orderData.length > 0 && orderData[0].data.length > 0) && (!['supervisor', 'crew'].includes($store.getters.currentUser.role))">
                <b-row class="mb-0">
                    <b-col md="12">
                        <h3 class="text-center mb-1">Order Summary</h3>
                        <div class="table-responsive" style="margin-bottom: 0px !important;">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Supplier</th>
                                        <th scope="col" class="text-center">Sub Total</th>
                                        <th scope="col" class="text-center">Delivery Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list, j) in orderData" :key="j">
                                        <td>{{list.supplier_name}}</td>
                                        <td class="text-center">
                                            {{ updateSubPrice(j) | showReadNum }}
                                        </td>
                                        <td class="text-center">
                                            <span v-if="list.fee_weiver == 'yes' && (Number(updateSubPrice(j)) >= Number(list.moq))">$0.00</span>
                                            <span v-else>
                                                <span v-if="enable_multiple_supplier_delivery_site == 'no'">$0.00</span>
                                                <span v-else>{{ list.delivery_fee | showReadNum }}</span>
                                            </span>
                                        </td>
                                    </tr>                                    
                                </tbody>
                                <thead>
                                    <tr>
                                        <th scope="col" class="pb-0"><span>Total Amount</span></th>
                                        <th scope="col" class="pb-0 text-center"><span>{{ viewSubTotal() | showReadNum }}</span></th>
                                        <th scope="col" class="pb-0 text-center"><span>{{ viewDeliveryTotal() | showReadNum }}</span></th>
                                    </tr>
                                    <tr>
                                        <th scope="col" class="pb-0"><span>Grand Total Amount</span></th>
                                        <th scope="col" class="pb-0 text-center" colspan="2"><span>{{ updatePrice() | showReadNum }}</span></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                    </b-col>
                </b-row>
                <hr class="mt-1">
                <b-row class="mb-0">
                    <b-col md="12">
                        <span><strong>Note :</strong> The delivery fee will be waived off during check out based on ordered amount.</span>
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="mt-1" v-if="orderData.length > 0 && orderData[0].data.length > 0">    
            
                <b-row>

                    <b-col cols="12" class="mb-2 mt-2">
                        
                    <div class="pb-2">
                        <b-form-group label="Signature" class="required"  style="font-size:20px;color:white;text-align:center" />
                        <center>
                            <div @click="openSignature('supervisor')" v-if="signature.image == null">
                            <!--<template #aside>-->
                                <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                            <!--</template>-->
                            </div>

                            <div class="d-flex flex-wrap">
                                <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                            </div>

                            <img :src="signature.image" width="200px" class="rounded" height="180" v-if="signature.image != null" style="background: #ffffff">
                        </center>

                        <center>
                            <b-button variant="danger" class="mt-1 ml-2" v-if="signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
                                <feather-icon icon="XIcon" />
                            </b-button>
                        </center>
                    </div>

                    </b-col>
                
                </b-row>
            
                <b-row>
                    <b-col>

                        <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        class = "mr-1"
                        variant="outline-secondary"
                        @click="goBack()"
                       
                        >
                        Cancel
                        </b-button>

                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="mr-1"
                        v-if="id != null && typeof $route.params.id == 'undefined'"
                        @click="discardItem(id)"
                        >
                        Discard Draft
                        </b-button>

                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        type="submit"
                        @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined'"
                        >
                        {{ id == null ? 'Save as Draft' : 'Update Draft' }}
                        </b-button>

                    

                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="mr-1"
                        type="submit"
                        @click="status = 'completed'"
                        >
                        {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
                        </b-button>

                        <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        v-if="typeof $route.params.id != 'undefined' && ledit == false"
                        @click="openSignature('approver')"
                        >
                        {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
                        </b-button>

                    

                    </b-col>
                </b-row>

            </b-card>
        </b-form>

        <b-modal
            id="signatureModel"
            ref="signatureModel"
            centered
            title="Signature"
            no-close-on-backdrop
            hide-footer
            @hide="hideSignature"
            @show="hideSignature"
          >
      
          <div v-if="show_default == true">
            <b-row>

              <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
                <center>
                  <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
                </center>

              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
              <b-col md="12">
                <date-picker v-model="approve_signature_time" 
                  format="DD MMM YYYY HH:mm" 
                  type="datetime"
                  placeholder="Select"
                  valueType="format"
                  :clearable=false
                  :confirm=true
                  lang="en"
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
                  :disabled-date="disabledDate"
                  ></date-picker>


              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
              <b-col md="12">
                <b-form-group
                    label="Remarks"
                    class=""
                  >
                    <b-form-textarea
                      placeholder=""
                      rows="3"
                      v-model="approve_remark"
                    />
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">

              <b-col md="6">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      @click="show_default = false"
                    >
                    <span class="text-nowrap">Add New Signature</span>
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-button
                      variant="warning"
                      class="mt-0 float-right w-100"
                      @click="saveDefault()"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <b-col md="12">
                <VueSignaturePad
                  id="signature"
                  width="100%"
                  height="300px"
                  ref="signaturePad"
                  :options="{ onBegin: () => { $refs.signaturePad.resizeCanvas() }, penColor: '#000000', minWidth: 3, maxWidth: 3 }"
                />

              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
              <b-col md="12">
                <date-picker v-model="approve_signature_time" 
                  format="DD MMM YYYY HH:mm" 
                  type="datetime"
                  placeholder="Select"
                  valueType="format"
                  :clearable=false
                  :confirm=true
                  lang="en"
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                  :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
                  :disabled-date="disabledDate"
                  ></date-picker>


              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
              <b-col md="12">
                <b-form-group
                    label="Remarks"
                    class=""
                  >
                    <b-form-textarea
                      placeholder=""
                      rows="3"
                      v-model="approve_remark"
                    />
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">

              <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
                <b-button
                    variant="primary"
                    class="mt-0 w-100"
                    @click="uploadSign()"
                  >
                  <span class="text-nowrap">Upload</span>
                </b-button>
              </b-col>

              <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      v-if="having_default == true"
                      @click="show_default = true"
                    >
                    <span class="text-nowrap">Default</span>
                  </b-button>
                </b-col>


              <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

                  <b-button
                      variant="danger"
                      class="mt-0 w-100"
                      @click="clearSignature"
                    >
                    <span class="text-nowrap">Clear</span>
                  </b-button>
                </b-col>

                <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
                  <b-button
                      variant="warning"
                      class="mt-0 w-100"
                      @click="saveSignature"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>
        </b-modal>

    </div>
</template>

<script>
import {
    BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable, BMedia, BForm, BFormTextarea, BModal, BBadge, BCardText, BFormInput, BFormGroup, BFormSelect, 
    BFormSelectOption, BFormCheckbox, BFormDatepicker, BBreadcrumb,VBTooltip,
    BCardBody, BCardTitle, BMediaAside, BMediaBody, BFormRadio
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    
    components: {
        BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable, BMedia, BForm, BFormTextarea, BModal, BBadge, BCardText, BFormInput, BFormGroup, BFormSelect, BFormSelectOption, 
        Datepicker, BFormCheckbox, BFormRadio, flatPickr, vSelect, BFormDatepicker, BBreadcrumb, DatePicker,
        BCardBody, BCardTitle, BMediaAside, BMediaBody
    },
    data() {
        return {
            checkOrderCount: false,
            orderCount: 0,
            date: moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
            id: null,
            site: '',
            order_type:'',
            order_by: this.$store.getters.currentUser.full_name,
            sites: [],
            status: 'draft',
            signature: { image: null, name: '', default: 'no' },
            showDismissibleAlert: false,
            error_message: '',
            doc_icon: require('@/assets/images/doc.png'),
            orderData: [],
            deliveryData:[],
            tempSign: {
                image: null,
                name: '',
            },
            show_default: false,
            having_default: false,
            supervisorSign: {
                image: null,
                name: '',
                type: 'supervisor',
                default: 'no'
            },
            approve_signature_time: null,
            approve_remark: '',
            
            tableColumns2 : [
                { key: 's_no', label: 'SNo', sortable: false, thStyle: { width: '5%' } },
                
                { key: 'site', label: 'Project Site', sortable: false, thStyle: { width: '40%' } },
                { key: 'address', label: 'Address', sortable: false, thStyle: { width: '40%' } },
                { key: 'action', label: '', sortable: false, thStyle: { width: '15%' } },
            ],

            tableColumns3: [
                
                { key: 'site', label: 'Project Site', sortable: false, thStyle: { width: '50%' } },
                { key: 'address', label: 'Address', sortable: false, thStyle: { width: '50%' } },
                
            ],
            
            suppliers:[],
            month: moment().tz('Asia/Singapore').format('MMMM'),
            //months:[],
            special_requisition:'',
            multiple_delivery_option:'no',
            ledit: false,
            disabledOptions:false,
            debounceTimeout: null,

            flag_data: false,
            enable_multiple_supplier_delivery_site: 'no',
            multiDeliveryData: [],
            role: this.$store.getters.currentUser.role,
            show_all_items: true,
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    watch: {
        orderData: {
            handler(newVal, oldVal) {
                // Update this.multiDeliveryData based on order key
                if(this.flag_data == false){
                    newVal.forEach(orderItem => {
                        const existingIndex = this.multiDeliveryData.findIndex(item => item.order === orderItem.order);
                        if (existingIndex === -1) {
                            this.multiDeliveryData.push({
                                order: orderItem.order,
                                supplier_name: orderItem.supplier_name,
                                supplier_id: orderItem.supplier_id,
                                site: '',
                                site_name: '',
                                address: '',
                                lat: '',
                                long: '',
                                supervisors: [],
                                operational_managers: [],
                                country: null,
                                _showDetails: false,
                                _from_db: false
                            });
                        } else {
                            // Update existing data if needed
                            this.multiDeliveryData[existingIndex].order = orderItem.order;
                            this.multiDeliveryData[existingIndex].supplier_name = orderItem.supplier_name;
                            this.multiDeliveryData[existingIndex].supplier_id = orderItem.supplier_id;
                            // Update other properties if needed
                        }
                    });
    
                    // Ensure the number of records in this.multiDeliveryData matches orderData
                    if (newVal.length !== this.multiDeliveryData.length) {
                        this.multiDeliveryData = newVal.map(item => ({
                            order: item.order,
                            supplier_name: item.supplier_name,
                            supplier_id: item.supplier_id,
                            site: '',
                            site_name: '',
                            address: '',
                            lat: '',
                            long: '',
                            supervisors: [],
                            operational_managers: [],
                            country: null,
                            _showDetails: false,
                            _from_db: false
                        }));
                    }
    
                    // Remove data from this.multiDeliveryData if it is removed from orderData
                    const removedOrders = oldVal.filter(item => !newVal.includes(item));
                    removedOrders.forEach(order => {
                        const index = this.multiDeliveryData.findIndex(item => item.order === order.order);
                        if (index !== -1) {
                            this.multiDeliveryData.splice(index, 1);
                        }
                    });
                }
            },
            deep: true
        }
    },

    methods: {
        updateSite(){
            this.multiDeliveryData = [];
            var site = this.sites.find(item => item._id == this.site);
            // console.log('+++++++', site);
            this.orderData.forEach((orderItem, ind) => {
                if(ind == 0){
                    this.multiDeliveryData.push({
                        order: orderItem.order,
                        supplier_name: orderItem.supplier_name,
                        supplier_id: orderItem.supplier_id,
                        site_name: site.site_name,
                        site: site._id,
                        address: site.address,
                        lat: site.lat,
                        long: site.long,
                        supervisors: [],
                        operational_managers: [],
                        country: null,
                        _showDetails: false,
                        _from_db: false
                    });
                    this.msdSiteChange(0);
                } else {
                    this.multiDeliveryData.push({
                        order: orderItem.order,
                        supplier_name: orderItem.supplier_name,
                        supplier_id: orderItem.supplier_id,
                        site: '',
                        site_name: '',
                        address: '',
                        lat: '',
                        long: '',
                        supervisors: [],
                        operational_managers: [],
                        country: null,
                        _showDetails: false,
                        _from_db: false
                    });
                }
            });
        },
        msdSiteChange(i){
            var site = this.sites.find(item => item._id == this.multiDeliveryData[i].site);

            this.multiDeliveryData[i].site_name  = site.site_name;
            this.multiDeliveryData[i].site       = site._id;
            this.multiDeliveryData[i].address    = site.address;
            this.multiDeliveryData[i].lat        = site.lat;
            this.multiDeliveryData[i].long       = site.long;
            
            this.multiDeliveryData[i].country = site.country;

            return this.$store.dispatch(POST_API, {
                data: {
                    site: site._id,
                },
                api: '/api/site-area-user-info'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    if (data.length > 0 && data[0].user) {
                        this.$set(this.multiDeliveryData[i], 'user', data[0].user);
                    } else {
                        this.$set(this.multiDeliveryData[i], 'user', null);
                    }
                    return this.multiDeliveryData;
                }
            });
        }, 
        siteDisableMSD(id) {
            var site_ids = [];
            for (var i = 0; i < this.multiDeliveryData.length; i++) {
                site_ids.push(this.multiDeliveryData[i].site);
            }


            if (site_ids.indexOf(id) >= 0) {
                return true;
            } else {
                return false;
            }

        },
        siteDisableMSD(id) {
            var site_ids = [];
            for (var i = 0; i < this.multiDeliveryData.length; i++) {
                site_ids.push(this.multiDeliveryData[i].site);
            }

            if (site_ids.indexOf(id) >= 0) {
                return true;
            } else {
                return false;
            }

        },
        matchItem(j){
            // console.log('++++++++',  this.orderData[j]);

            clearTimeout(this.debounceTimeout);

            this.debounceTimeout = setTimeout(() => {
                for (let i = 0; i < this.orderData[j].data.length; i++) {
                    if (this.orderData[j].data[i].name.toLowerCase().includes(this.orderData[j].filter_items.toLowerCase())) {
                        this.orderData[j].data[i].matched = true;
                    } else {
                        this.orderData[j].data[i].matched = false;
                    }
                }
            }, 50); // Adjust the delay as needed (e.g., 300 milliseconds)

            
            /* if(key != undefined && key.trim() != ''){
                item = item.toLowerCase();
                key = key.toLowerCase();
    
                return item.includes(key);

            } else {return true} */

        },
        siteChange(){
            this.site != '' ? localStorage.setItem('routeCFCheck', 'yes') : localStorage.setItem('routeCFCheck', 'no');
            
            this.order_type = '',
            this.order_by = this.$store.getters.currentUser.full_name;
            
            this.orderData = [];

            if (this.site != '') {
                this.allSuppliers();

                if(this.order_type != ''){
                    this.getData().then(() => {
                        this.dlSiteChange(this.site, 0);
                    });
                }
            }
            this.checkMonthlyReportCount();
        },
        useDefault() {

            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.$store.getters.currentUser._id
                },
                api: '/api/get-default-signature'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    if (data == null || data.default_signature == null) {
                        this.having_default = false;
                    } else {
                        this.tempSign = {
                            image: data.default_signature,
                            name: 'signature.png',
                        }

                        this.having_default = true;
                    }
                }
            });
        },
        allSites() {
            var api = this.$route.params.id ? '/api/all-inventory-sites' : '/api/all-inventory-sites-2';
            
            return this.$store.dispatch(POST_API, {
                data: {
                    role: this.$store.getters.currentUser.role,
                    //om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: api
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    this.sites = data;

                    if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                        this.site = this.sites[0]._id;
                        this.siteChange();
                    }
                    return this.sites;
                }
            });
        },
        getData() {
            localStorage.setItem('routeCFCheck', 'no');
            return this.$store.dispatch(POST_API, {
                data: {
                    site: this.site != '' ? this.site : null,
                    date: this.date,
                    month:this.month,
                    order_type:this.order_type
                },
                api: '/api/inventory-order-site-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;
                    this.id = data.id;
                    // this.orderData = data.orderData;
                    this.orderData = data.orderData.map(obj => ({
                        ...obj,
                        data: obj.data.map(dataObj => ({ ...dataObj, matched: true }))
                    }));

                    this.signature = data.signature;
                    this.multiple_delivery_option = data.delivery_option;
                    this.special_requisition = data.special_requisition;
                    this.deliveryData = data.deliveryData;
                    
                    // const temp_site = this.sites.find(item => item._id == this.site);
                    
                    // this.deliveryData[0]
                    
                    // this.deliveryData[0] = {
                    //     site: temp_site._id,
                    //     site_name: temp_site.site_name,
                    //     address: temp_site.address,
                    //     lat: temp_site.lat,
                    //     long: temp_site.long,
                    //     user: null,
                    //     country: temp_site.country,
                    //     _showDetails: false,
                    //     _from_db: false
                    // }

                    this.flag_data = true;

                    this.enable_multiple_supplier_delivery_site = data.enable_multiple_supplier_delivery_site;
                    this.multiDeliveryData = data.multiDeliveryData;
                    
                    return this.orderData;
                }
            });
        },
        allSuppliers() {
            return this.$store.dispatch(POST_API, {
                data: {
                    role: this.$store.getters.currentUser.role,
                    item_exist:true,
                    site: this.site
                    //om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-suppliers'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    this.suppliers = data;
                    return this.suppliers;
                }
            });
        },
        addMore() {
            var order = 0;

            if (this.orderData.length > 0) {
                var lastIndex = this.orderData.length - 1;
                order = this.orderData[lastIndex].order + 1;
            }

            var obj = {
                supplier: '',
                supplier_name: '',
                category: '',
                
                categories: [],
                sub_category: '',
               
                sub_categories: [],
                data: [],
                order: order,
                db_order: false,
                delivery_fee: '',
                moq: '',
            };

            this.orderData.push(obj);
        },
        removeData(index) {
            this.orderData.splice(index, 1);
            if (this.orderData.length == 0) {
                this.addMore();
            }

            this.multiDeliveryData.splice(index, 1);
            if (this.multiDeliveryData.length == 0) {
                this.multiDeliveryData.push({
                    order: '',
                    supplier_name: '',
                    supplier_id: '',
                    site: '',
                    site_name: '',
                    address: '',
                    lat: '',
                    long: '',
                    supervisors: [],
                    operational_managers: [],
                    country: null,
                    _showDetails: false,
                    _from_db: false
                });
            }
        },
        breadCrumb() {

            var item = [{
                to: { name: 'client-dashboard' },
                text: 'Dashboard',
            }, {
                to: null,
                text: 'Inventory'
            }, {
                to: { name: 'inventory-order' },
                text: 'Overview',
               
            }, {
                to: null,
                text: 'Add Order',

            }];
            return item;  
        },
        supervisorImageUpload(event) {
            var input = event.target;
            var files = event.target.files

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var image = input.files[0];

                if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please upload .jpg, .jpeg, .jpg images only',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    this.$refs.supervisorSInput.value = null;

                } else if (image.size > 2097152) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.supervisorSInput.value = null;
                } else {
                    reader.onload = (e) => {
                        this.signature.image = e.target.result;
                        this.signature.name = image.name;
                        this.signature.default = 'no';
                    }
                }
            }
        },
        removeSign(sign) {
            this.signature.image = null;
            this.signature.name = '';
            this.signature.default = 'no';
        },
        uploadSign() {

            this.$refs['supervisorSInput'].click();
            this.$refs['signatureModel'].hide();

        },
        openSignature(type) {
            if(this.special_requisition.length > 0 && this.$route.params.id){
                this.customAlert('', 'There is Special Requisition. Please Acknowledge.', 'info');
            } else {
                this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
    
                if (this.openedSignModel == null) {
    
                    if (this.having_default == true) {
                        this.show_default = true;
                    } else {
                        this.show_default = false;
                    }
    
                    this.$refs['signatureModel'].show();
                    this.openedSignModel = type;
                }
            }
        },
        hideSignature() {
            this.openedSignModel = null;
        },
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        saveSignature() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            if (isEmpty) {

                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Signature is required',
                    showConfirmButton: false,
                    timer: 1500
                })

            } else {
                if (this.openedSignModel == 'supervisor') {

                    this.signature.image = data;
                    this.signature.name = 'signature.png';
                    this.signature.type = 'supervisor';
                    this.signature.default = 'no';
                    this.$refs['signatureModel'].hide();
                } else {
                    this.supervisorSign.image = data;
                    this.supervisorSign.name = 'signature.png';
                    this.supervisorSign.type = 'supervisor';
                    this.supervisorSign.default = 'no';
                    this.status = 'approved';
                    this.actualForm();
                }
            }
        },
        saveDefault() {

            if (this.openedSignModel == 'supervisor') {
                this.signature = {
                    image: this.tempSign.image,
                    name: 'signature.png',
                    default: 'yes'
                }
            } else {

                this.supervisorSign = {
                    image: this.tempSign.image,
                    name: 'signature.png',
                    default: 'yes',
                    type: 'supervisor'
                }
                this.status = 'approved';
                this.actualForm();

            }

            this.$refs['signatureModel'].hide();
        },
        updateSupplier(index){

            this.orderData[0].supplier != '' ? localStorage.setItem('routeCFCheck', 'yes') : localStorage.setItem('routeCFCheck', 'no');

            if(this.orderData[index].supplier != ''){
                var fsupp = this.suppliers.find(item => { return item._id == this.orderData[index].supplier });
               this.orderData[index].supplier_name = fsupp != null ? fsupp.name : ''; 
               this.orderData[index].delivery_fee = fsupp != null ? fsupp.delivery_fee : '';
               this.orderData[index].moq = fsupp != null ? fsupp.moq : '';
            }else{
               this.orderData[index].supplier_name = ''; 
               this.orderData[index].delivery_fee = '';
               this.orderData[index].moq = '';
            }

            this.orderData[index].category = '';
            this.orderData[index].categories = [];
            this.orderData[index].sub_category = '';
            this.orderData[index].sub_categories = [];
            this.orderData[index].filter_items = '';
            this.orderData[index].data =[];

            this.getAllSuppliersItem(this.orderData[index].supplier,index);

            this.getCategories(index);

        },
        updateCategory(index) {
            
            // if (this.orderData[index].category != '') {
            //     var fsupp = this.orderData[index].categories.find(item => { return item._id == this.orderData[index].category });
            //     this.orderData[index].category_name = fsupp != null ? fsupp.name : '';
            // } else {
            //     this.orderData[index].category_name = '';
            // }

            this.orderData[index].sub_category = '';
            this.orderData[index].sub_categories = [];
            
            this.getSubCategories(index);

        },
        
        getCategories(index) {
            return this.$store.dispatch(POST_API, {
                data: {
                    supplier: this.orderData[index].supplier != '' ? this.orderData[index].supplier : null,
                    site:this.site,
                    item_exist:true,
                },
                api: '/api/all-suppliers-categories'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    var ind = null;
                    data.forEach((item,i)=> {
                        if(item.name == 'General'){
                            ind = i
                        }
                    })

                    if(ind != null){

                        var obj = data[ind];
                        data.splice(ind, 1);
                        data.unshift(obj);

                    }
                    

                    this.orderData[index].categories = data;


                    return this.orderData;
                }
            });
        },

        getSubCategories(index) {
            return this.$store.dispatch(POST_API, {
                data: {
                    supplier: this.orderData[index].supplier != '' ? this.orderData[index].supplier : null,
                    category: this.orderData[index].category != '' ? this.orderData[index].category : null,
                    site: this.site,
                    item_exist:true,
                },
                api: '/api/all-suppliers-sub-categories'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;
                    
                    if(this.$store.getters.getResults.show_general == true){
                        
                        data.unshift({ _id: "general", name: "General" });

                    }
                    this.orderData[index].sub_categories = data;
                    return this.orderData;
                }
            });
        },
        
        
        calAmount(i,index){
            this.orderData[i].data[index].amount = (this.orderData[i].data[index].unit_price * this.orderData[i].data[index].qty).toFixed(2);

            // console.log(this.orderData[i].data[index].amount);
            // console.log(this.orderData[i].data[index]);
            // console.log(this.orderData[i].data);
            // console.log(this.orderData[i]);

            return this.orderData[i].data[index].amount;
        },
        isNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        changetype(e){
            
            if(e == 'monthly'){
                this.month = moment(this.date).add(1,'months').format('MMMM');
                
            }else{
                this.month = moment(this.date).format('MMMM');
            }

            if(this.site != ''){
                
                this.getData().then(() => {
                    // console.log('++++11');
                    this.dlSiteChange(this.site, 0);
                });
            }
        },
        updateSubPrice(i){
            var amount = 0;
            
            this.orderData[i].data.forEach(t => {
                amount = parseFloat(amount) + parseFloat(t.amount == '' ? 0 : t.amount);
            })

            return amount.toFixed(2);
        },
        updatePrice(){
            var amount = 0;
            this.orderData.forEach(item => {
                
                item.data.forEach(t => {
                    amount = parseFloat(amount) + parseFloat(t.amount == '' ? 0 : t.amount);
                })

                // console.log(item.supplier_name, item.fee_weiver, amount, item.moq, !(item.fee_weiver == 'yes' && amount > item.moq))

                if(!(item.fee_weiver == 'yes' && amount >= Number(item.moq))){
                    if(this.enable_multiple_supplier_delivery_site == 'no'){
                        amount = parseFloat(amount);
                    } else {
                        amount = parseFloat(amount) + parseFloat(item.delivery_fee == '' ? 0 : item.delivery_fee);
                    }
                }

            })

            return amount.toFixed(2);
        },

        viewSubTotal(){
            var amount = 0;
            this.orderData.forEach(item => {
                
                item.data.forEach(t => {
                    amount = parseFloat(amount) + parseFloat(t.amount == '' ? 0 : t.amount);
                })

            })

            return amount.toFixed(2);
        },
        
        viewDeliveryTotal(){
            var amount = 0;
            var del_amount = 0;
            this.orderData.forEach(item => {

                item.data.forEach(t => {
                    amount = parseFloat(amount) + parseFloat(t.amount == '' ? 0 : t.amount);
                })

                if(!(item.fee_weiver == 'yes' && amount >= Number(item.moq)) && this.enable_multiple_supplier_delivery_site == 'yes'){
                    del_amount = parseFloat(del_amount) + parseFloat(item.delivery_fee == '' ? 0 : item.delivery_fee); 
                }

            })

            return del_amount.toFixed(2);
        },

        addDLMore(){
            this.deliveryData.push({
                site: '',
                site_name: '',
                address: '',
                lat: '',
                long: '',
                supervisors:[],
                operational_managers:[],
                country: null,
                _showDetails: false,
                _from_db: false
            })
        },
        
        removeDeliveryItem(i){
            this.deliveryData.splice(i,1);

            if(this.deliveryData.length == 0){
                this.addDLMore();
            }
        },
        checkdisabled() {
            if (this.ledit == true) {
                return false;
            } else if (this.disabledOptions == true) {
                return true;
            } else {
                return false;
            }
        },
        checkDisabled(id){
            var allIds = this.deliveryData.map(item => {return item.site});
            return allIds.includes(id)
            
        },
        dlSiteChange(id,i){
            
            var site = this.sites.find(item => item._id == id);
            this.deliveryData[i].site_name  = site.site_name;
            this.deliveryData[i].site       = site._id;
            this.deliveryData[i].address    = site.address;
            this.deliveryData[i].lat        = site.lat;
            this.deliveryData[i].long       = site.long;
            
            this.deliveryData[i].country = site.country;

            return this.$store.dispatch(POST_API, {
                data: {
                    site: id,
                },
                api: '/api/site-area-user-info'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;
                    this.deliveryData[i].user = data.length > 0 ? data[0].user : null;
                    //console.log(data);
                }
            });





            return this.deliveryData;
        }, 
        
        goBack() {

            var msg = 'Do you want to Go back without saving your report?';

            this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        localStorage.setItem('routeCFCheck', 'no');
                        
                        this.$router.push({ name: 'inventory-order' })


                    }
                })
        },
        formSubmit(e) {
            e.preventDefault();
            
            var roles = ['administrator', 'admin', 'operation_manager', 'operation_executive', 'site_client', 'site_manager', 'crew'];

            if(roles.includes(this.$store.getters.currentUser.role) == false && this.orderCount > 0 && this.order_type == 'monthly'){
                this.customAlert('', 'Inventory Order Already Exists for this month.', 'info')
            } else {
                if (this.status == 'draft') {
                    this.actualForm();
                } else {
                    this.decisionAlert('Are you sure you want to submit this report?')
                    .then(result => {
                        if (result.value) {
                            this.actualForm();
                        }
                    })
                }
            }
        },
        handleHiddenDeliveryData() {
            return new Promise((resolve) => {

                for (let i = 0; i < this.multiDeliveryData.length; i++) {
                    this.multiDeliveryData[i].address = this.deliveryData[0].address;
                    this.multiDeliveryData[i].country = this.deliveryData[0].country;
                    this.multiDeliveryData[i].lat = this.deliveryData[0].lat;
                    this.multiDeliveryData[i].long = this.deliveryData[0].long;
                    this.multiDeliveryData[i].site = this.deliveryData[0].site;
                    this.multiDeliveryData[i].site_name = this.deliveryData[0].site_name;
                    this.multiDeliveryData[i].user = this.deliveryData[0].user;
                    if(i == this.multiDeliveryData.length - 1){
                        resolve(true)
                    }
                }
            })
        },
        async actualForm(){
            var orderData = this.orderData.map(({ filter_items, ...rest }) => rest);

            var final_orderData = orderData.map(item => ({
                ...item,
                data: item.data.map(({ matched, ...rest }) => rest),
            }));
            
            if(this.$store.getters.currentUser.role == 'supervisor'){
                // console.log(true)
                await this.handleHiddenDeliveryData();
            }
            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.id,
                    site: this.site,
                    order_type: this.order_type,
                    date: this.date,
                    month: this.month,
                    order_by: this.order_by,
                    orderData: final_orderData,
                    special_requisition: this.special_requisition,
                    deliveryData : this.deliveryData,
                    multiple_delivery_option : this.multiple_delivery_option,
                    signature: this.signature,
                    status: this.status,
                    form_id: this.$route.params.id,
                    role: this.$store.getters.currentUser.role,
                    approve_signature: this.supervisorSign,
                    approve_signature_time: this.approve_signature_time,
                    approve_remark: this.approve_remark,
                    total_amount: this.updatePrice(),
                    enable_multiple_supplier_delivery_site: this.enable_multiple_supplier_delivery_site,
                    multiDeliveryData: this.multiDeliveryData,
                },
                api: '/api/submit-inventory-order'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.errorAlertCF()
                } else {
                    this.showDismissibleAlert = false;

                    var data = this.$store.getters.getResults.data;

                    this.successAlert().then((result) => {

                        if (this.sites.length == 1) {
                            this.site = this.sites[0]._id;
                        } else {
                            this.site = '';
                        }
                        this.siteChange();

                        if (data != null) {
                            window.open(data, '_blank');
                        }
                        localStorage.setItem('routeCFCheck', 'no');

                        Bus.$emit('counter_update');
                        
                        this.$router.push({ name: 'inventory-order' });
                    });
                }
            });


        },
        checkUnique(i) {
            var arr = [];
            var flag = false;

            for(var i=0;i < this.orderData.length ; i++){

                var item = this.orderData[i];
                
                if (arr.indexOf(item.supplier + item.category + item.sub_category) >= 0) {
                    this.customAlert('Supplier Items Category Information already Exists.', '', 'warning');

                    this.orderData[i].sub_category = '';
                    this.orderData[i].data = [];
                    flag =true;

                    i = this.orderData.length;
                } else {
                    arr.push(item.supplier + item.category + item.sub_category);
                }

            }

            return flag;

            this.orderData.forEach((item) => {
                
            })

            return false;
        },
        updateSupplierData(index, item) {
            // console.log(index,item);
            return this.$store.dispatch(POST_API, {
                data: {
                    site     : this.site,
                    supplier : item.supplier,
                },
                api: "/api/fetch-latest-supplier-items",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showAlert();
                } else {
                    let data = this.$store.getters.getResults.data;
                    
                    const existingIds = item.data.map(item => item._id);

                    var i = item.data.length + 1;

                    data.forEach(el => {
                        if (!existingIds.includes(el._id)) {
                            item.data.push({
                                '_id': el._id,
                                'code': el.code,
                                'name': el.name,
                                'category': el.category,
                                'sub_category': el.sub_category,
                                'packing': el.packing,
                                'unit_price': el.unit_price,
                                'enabled': 'no',
                                'amount': '',
                                'bal': 0,
                                'qty': 0,
                                'most_order': 'no',
                                'order': i,
                                'matched': true
                            })

                            i++;
                        }
                    });
                }
            });
        },
        removeDBData(index, item) {
            //console.log(index,item);

            var msg = 'Are you sure you want to remove this Supplier Items record?';

            this.decisionAlert(msg)
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data: {
                            supplier: item.supplier,
                            id: this.id,
                        },
                        api: "/api/remove-inventory-order-db-supplier",
                    })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                //localStorage.setItem('routeCFCheck','no');
                                this.successAlert()
                                    .then(() => {

                                        this.orderData.splice(index, 1);
                                        if (this.orderData.length == 0) {
                                            this.addMore();
                                        }

                                        this.multiDeliveryData.splice(index, 1);
                                        if (this.multiDeliveryData.length == 0) {
                                            this.multiDeliveryData.push({
                                                order: '',
                                                supplier_name: '',
                                                supplier_id: '',
                                                site: '',
                                                site_name: '',
                                                address: '',
                                                lat: '',
                                                long: '',
                                                supervisors: [],
                                                operational_managers: [],
                                                country: null,
                                                _showDetails: false,
                                                _from_db: false
                                            });
                                        }

                                    });
                            }
                        });
                }
            })
            .catch(err => {
                console.log(err);
            })
        },
        
        discardItem(id) {
            var msg = 'Are you sure you want to discard this record?';

            this.decisionAlert(msg)
            .then(result => {
                if (result.value) {
                    return this.$store.dispatch(POST_API, {
                        data: {
                            id: id,
                            status: 'deleted'
                        },
                        api: "/api/change-status-inventory-order-report-data",
                    })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                localStorage.setItem('routeCFCheck', 'no');
                                this.successAlert()
                                    .then(() => {
                                        
                                        this.$router.push({ name: 'inventory-order' })
                                    });
                            }
                        });
                }
            })
            .catch(err => {
                console.log(err);
            })
        }, 
        disabledDate(date) {
            return date < moment(new Date(this.date)).subtract(1, 'days').tz('Asia/Singapore');
        },
        getFormDetails() {
            /*localStorage.setItem('routeCFCheck','yes');*/

            return this.$store.dispatch(POST_API, {
                data: {
                    id: this.$route.params.id
                },
                api: '/api/get-inventory-order-report-detail'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0, 0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;

                        

                        this.id = data._id;
                        this.date = data.date;
                        this.site = data.site;
                        this.order_by = data.order_by;
                        this.month = data.month;
                        this.order_type = data.order_type;
                        
                        this.orderData = data.orderData;
                        this.status = data.status;

                        this.signature = {
                            image: data.signature,
                            name: 'signature.png',
                            type: 'supervisor',
                            default: 'yes'
                        };
                        this.special_requisition = data.special_requisition;

                        if(this.special_requisition.length > 0){
                            this.customAlert('', 'There is Special Requisition. Please Acknowledge.', 'info')
                        }

                        this.multiple_delivery_option = data.delivery_option;
                        this.deliveryData = data.deliveryData;

                        this.flag_data = true;

                        this.enable_multiple_supplier_delivery_site = data.enable_multiple_supplier_delivery_site;
                        this.multiDeliveryData = data.multiDeliveryData;


                        var role = this.$store.getters.currentUser.role;

                        if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                            //this.disabledOptions = false;
                            this.ledit = true;
                        } 

                        this.allSuppliers();
                    }
                });

        },
        showNumberFormat(phone_number,country) {
            //console.log(phone_number,country);
            var prefix = '';
            if(country == 'Singapore'){
                prefix = '+65';
            }else{
                prefix = '+66';
            }
            
            if(phone_number.length == 8){
                
                return prefix + ' ' + phone_number.replace(/(\d{4})(\d{4})/, "$1 $2");
            }else{
                return prefix + ' ' + phone_number;
            }
            
        },
        getAllSuppliersItem(supplier,index){
             return this.$store.dispatch(POST_API, {
                data: {
                    supplier: supplier,
                    site:this.site,
                    order_type: this.order_type
                },
                api: '/api/get-all-supplier-items'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0, 0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data.map(dataObj => ({ ...dataObj, matched: true }))

                    this.orderData[index].data = data;
                    if(data.length > 0){
                        this.orderData[index].fee_weiver = data[0].fee_weiver;
                    }
                    //console.log(data);
                }
            });
        },
        supplierDisable(id) {
            var sup_ids = [];
            for (var i = 0; i < this.orderData.length; i++) {
                if (this.orderData[i].supplier != '') {
                    sup_ids.push(this.orderData[i].supplier);
                }
            }


            if (sup_ids.indexOf(id) >= 0) {
                return true;
            } else {
                return false;
            }

        },

        siteDisable(id) {
            var site_ids = [];
            for (var i = 0; i < this.deliveryData.length; i++) {
                site_ids.push(this.deliveryData[i].site);
            }


            if (site_ids.indexOf(id) >= 0) {
                return true;
            } else {
                return false;
            }

        },
        resetFilter(i){
            this.orderData[i].category = '';
            this.orderData[i].sub_category = '';
            this.orderData[i].sub_categories = [];

            this.orderData[i].data = this.orderData[i].data.map(dataObj => ({ ...dataObj, matched: true }));

            // this.orderData = data.orderData.map(obj => ({
            //     ...obj,
            //     data: obj.data.map(dataObj => ({ ...dataObj, matched: true }))
            // }));
            // this.orderData[i].filter_items = '';
        },
        updateItemValue(i,index){
            //this.orderData[i].data[index].enabled
            if(this.orderData[i].data[index].enabled == 'no'){
                this.orderData[i].data[index].qty = '0';
                this.orderData[i].data[index].amount = '';
                this.orderData[i].data[index].bal = '0';

            }
        },

        checkMonthlyReportCount(){
            return this.$store.dispatch(POST_API, {
            data: {
                role: this.$store.getters.currentUser.role,
                site: this.site != '' ? this.site : null,
            },
            api: '/api/check-inventory-order-monthly-count'
            })
            .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                var roles = ['administrator', 'admin', 'operation_manager', 'operation_executive', 'site_client', 'site_manager', 'crew'];
                if(this.$store.getters.getResults.data > 0 && roles.includes(this.$store.getters.currentUser.role)){
                    this.checkOrderCount = false;
                    this.orderCount = this.$store.getters.getResults.data;
                } else {
                    this.checkOrderCount = true;
                    this.orderCount = this.$store.getters.getResults.data;
                }

            }
            });
        }

    },
    mounted() {
        //this.createAuditMonth();
        this.useDefault();
        this.allSites();
        // this.checkMonthlyReportCount();        

        if (this.$route.params.id) {
            this.disabledOptions = true;
            this.checkOrderCount = true;
            this.getFormDetails().then(() => {
                this.orderData.forEach((item, index) => {
                    this.resetFilter(index);
                });
            });
        }

        if((this.role == 'director' || this.role == 'senior_operation_manager' || this.role == 'operation_manager' || this.role == 'admin') && this.$route.params.id){
            this.show_all_items = false;
        }
    }
}
</script>

<style scoped>
.form-inline .custom-control-label {
    margin-bottom: 0;
    font-weight: normal;
    margin-top: 2px;
    text-transform: capitalize;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
